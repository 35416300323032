<template>
  <Section>
    <Container>
      <div class="title-bar margin-bottom">
        <h2>{{ pageTitle }}</h2>
      </div>
    </Container>
    <Container>
      <div class="table-grouping-wrapper" v-if="!isLoading">
        <template v-if="content && content.length">
          <table class="table-grouping" :class="{ 'table-grouping--variables': $route.name.includes('Variables') }">
            <thead>
              <tr>
                <th></th>
                <template v-for="trial in trials" :key="trial.id">
                  <th>
                    <div class="th-container" :title="trial.nom">
                      <div class="th-title">{{ trial.nom }}</div>
                      <div class="th-number">{{ trial.numero }}</div>
                      <div class="th-year">{{ trial.protocole?.annee_recolte?.valeur }}</div>
                      <div class="th-coop">{{ trial.protocole?.entite?.nom }}</div>
                    </div>
                  </th>
                </template>
              </tr>
            </thead>
            <tbody>
              <template v-for="(c, i) in content" :key="c.id">
                <tr>
                  <th>
                    <div class="th-container">
                      <div class="th-button">
                        <button v-ripple :class="c.button?.class" @click="toggleRow(c)"
                          :disabled="c.button?.class === 'disabled'" type="button">
                        </button>
                      </div>
                      <div class="th-infos" v-if="!$route.name.includes('Variables')">
                        <div class="th-id">[{{ c.id.toString().padStart(2, '0') }}]</div>
                        <div class="th-name" :title="c.designation">{{ c.designation }}</div>
                      </div>
                      <div class="th-infos" v-else>
                        <div class="th-target" :title="c.cible?.valeur">
                          {{ c.notation ? `${c.notation}` : '---' }}
                        </div>
                        <div class="th-name" :title="c.designation">
                          {{ c.designation }}
                        </div>
                        <div class="th-target" :title="c.cible?.valeur">
                          {{ c.cible ? `${c.cible.valeur}` : '---' }}
                        </div>
                        <div class="th-organ" :title="`${ c.organes && c.organes.length ? `${c.organes[0].designation}` : '' }`">
                          {{ c.organes && c.organes.length ? `${c.organes[0].designation}` : '---' }}
                        </div>
                      </div>
                    </div>
                  </th>
                  <template v-for="t in c.essais" :key="t.id">
                    <td>
                      <template v-if="t.selected !== null">
                        <button :class="{ 'active': t.selected }" v-ripple @click="toggleTrial(t, i)" type="button">
                        </button>
                      </template>
                    </td>
                  </template>
                </tr>
              </template>
            </tbody>
          </table>
        </template>
        <template v-else>
          Aucune {{ contentType }} n'est disponible
        </template>
      </div>
    </Container>
  </Section>
</template>

<script>
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'

export default {
  name: 'GroupingContentAddEditView',
  components: {
    Container,
    Section,
  },

  props: {
    pageTitle: {
    },
    modelValue: {
    },
    groupingProp: {
    },
  },

  data() {
    return {
      isLoading: true,
      trials: [],
      content: [],
      grouping: this.groupingProp,
      routeParams: {
        id: this.$route.params.id,
        rid: this.$route.params.rid,
      },
      contentType: 'modalité',
    }
  },
  emits: ['set-step', 'set-route', 'update:modelValue', 'set-grouping'],
  watch: {
    modelValue() {
      if (this.modelValue) {
        this.handleValues()
      }
      this.$emit('update:modelValue', false)
    },
    '$route.name': function () {
      if (this.$route.params.rid) {
        this.setDatas()
      }
    },
  },
  mounted() {
    this.setDatas()
  },
  methods: {
    setDatas() {
      this.emitter.emit('open-loader')
      this.isLoading = true
      this.$emit('set-step', !this.$route.name.includes('Variables') ? 1 : 2)
      this.$emit('set-route', {
        previousRouteName: !this.$route.name.includes('Variables') ? 'groupingEssaisAdd' : 'groupingModalitiesAdd',
        routeParams: this.routeParams,
      })

      this.fetchService.get(`protocole/${this.$route.params.id}/regroupement_essai/${this.$route.params.rid}`).then((response) => {
        this.trials = response?.data?.essais
        this.content = response?.data?.modalites
        this.grouping = response?.data

        if (this.$route.name.includes('Variables')) {
          this.content = this.grouping?.variables
          this.contentType = 'variable'
        }

        this.isLoading = false
        this.emitter.emit('close-loader')
        // this.$emit('set-grouping', response?.data ?? null)

        this.updateContentClasses()
      })
    },
    toggleTrial(trial) {
      // eslint-disable-next-line
      trial.selected = !trial.selected
      this.updateContentClasses()
    },
    toggleRow(row) {
      let content = null

      this.content.forEach((m) => {
        if (m.evtprotocolesource === row.evtprotocolesource && m.id === row.id) {
          if (row.cible && row?.cible?.id) {
            if (m?.cible?.id === row?.cible?.id) {
              content = m
            }
          } else {
            content = m
          }
        }
      })

      const { essais } = content

      const isActive = Object.values(essais).filter((m) => m.selected !== null).every((button) => button.selected || button.selected === null)
      const isMixed = Object.values(essais).filter((m) => m.selected !== null).some((button) => button.selected) && !isActive

      content.button = {}

      Object.values(essais).filter((m) => m.selected !== null).forEach((button) => {
        // eslint-disable-next-line
        button.selected = !isActive;
      })

      if (isMixed) {
        content.button.class = 'active'
      } else if (isActive) {
        content.button.class = 'inactive'
      } else {
        content.button.class = 'active'
      }
    },
    updateContentClasses() {
      this.content.forEach((content) => {
        const { essais } = content
        const activeButtons = Object.values(essais || {}).filter((m) => m.selected === true)
        const inactiveButtons = Object.values(essais || {}).filter((m) => m.selected === false)
        const nullButtons = Object.values(essais || {}).filter((m) => m.selected === null)
        const newButton = {}

        if (activeButtons.length === 0) {
          newButton.class = 'inactive'
        } else if (essais && activeButtons.length === Object.values(essais).filter((m) => m.selected !== null).length) {
          newButton.class = 'active'
        } else {
          newButton.class = 'mixed'
        }

        if ((nullButtons.length === Object.values(essais).length) || (activeButtons.length === 0 && nullButtons.length === 0 && inactiveButtons.length === 0)) {
          newButton.class = 'disabled'
        }

        // eslint-disable-next-line
        content.button = { ...newButton }
      })
      console.log('setDatas')
    },
    handleValues() {
      this.emitter.emit('open-loader')

      const params = {}
      const isVariablesRoute = this.$route.name.includes('Variables')

      if (!isVariablesRoute) {
        params.modalites = this.content
        params.variables = null
      } else {
        params.variables = this.content
      }

      const contentType = isVariablesRoute ? 'variable' : 'modalite'
      const endpoint = `protocole/${this.$route.params.id}/regroupement_essai/${this.grouping.id}/${contentType}`

      const addModalities = () => {
        this.fetchService.patch(endpoint, params)
          .then((r) => {
            this.$emit('set-grouping', r.data)

            const routeName = isVariablesRoute ? 'groupingSummaryAdd' : 'groupingVariablesAdd'
            const routeParams = { id: this.$route.params.id, rid: this.$route.params.rid }

            this.$router.push({ name: routeName, params: routeParams })
          })
          .catch((e) => {
            const errorContentType = isVariablesRoute ? 'variables' : 'modalités'
            this.emitter.emit('alert', {
              type: 'error',
              content: `Une erreur s'est produite lors de l'ajout des ${errorContentType}.`,
            })
            this.emitter.emit('alert', {
              type: 'error',
              content: `${e.data.fields[errorContentType]}`,
            })
          })
          .finally(() => {
            this.emitter.emit('close-loader')
          })
      }

      addModalities()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-grouping {
  $table-grouping-border-color: $color-gray-light;

  font-size: $font-size-small;
  line-height: $line-height-small;
  // border-collapse:collapse;
  // border: 2px solid $table-grouping-border-color;
  border-collapse: separate;
  border-spacing: 0;

  th,
  td {
    border: 1px solid $table-grouping-border-color;
    padding: 0.2rem;
  }

  th {
    font-weight: $font-weight-normal;
    background-color: white;
  }

  td {
    border-top: none;
    border-left: none;
  }

  thead {

    th {
      position: sticky;
      top: 0;
      z-index: +1;

      &:first-child {
        left: 0;
        z-index: +2;
        border-left-color: white;
        border-top-color: white;
      }

      &:not(:first-child) {
        padding: $gutter-quarter;
        writing-mode: vertical-rl;
        text-align: left;
        transform-origin: top left;
        transform: translate3d(calc(100% - 1px), 100%, 0) rotate(0.5turn
            /* + 0.125turn*/
          );
        max-height: 20rem;
      }

      &:not(:first-child):not(:last-child) {
        border-left: none;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        th {
          background-color: $color-gray-lightestest;
        }
      }

    }

    th {
      position: sticky;
      left: 0;
      border-top: none;
      max-width: 24rem;
      padding: $gutter-eighth $gutter-quarter;

      .th-container {
        display: flex;
        gap: $gutter-half;
        align-items: center;
        text-align: left;
      }
    }

    td {
      button {
        display: block;
        width: 100%;
        min-height: 2rem;
        border: none;
        background-color: $color-grouping-inactive;
        cursor: pointer;
        transition: all 0.125s;

        @include hocus() {
          background-color: $color-grouping-inactive-dark;
        }

        &.active {
          background-color: $color-grouping-active;

          @include hocus() {
            background-color: $color-grouping-active-dark;
          }
        }
      }

    }
  }
}

.th-infos {
  overflow: hidden;
  display: flex;
  gap: $gutter-half;
  align-items: center;
  text-align: left;

  >* {
    max-width: 100%;
  }

  .table-grouping--variables & {
    // display: block;
    flex-direction: column;
    gap: $gutter-eighth / 2;
    align-items: flex-start;
  }
}

.th-id {
  font-family: $font-family-monospace;
}

.th-id,
.th-title,
.th-name {
  font-weight: $font-weight-semibold;
}

.th-coop,
.th-organ {
  font-style: italic;
}

.th-coop,
.th-year,
.th-target {
  color: $color-gray-dark;
}

.th-coop,
.th-organ,
.th-target,
.th-title,
.th-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.th-button {
  button {
    display: block;
    width: 2rem;
    min-height: 2rem;
    border: none;
    cursor: pointer;
    transition: all 0.125s;

    &.active {
      background-color: $color-grouping-active;

      @include hocus() {
        background-color: $color-grouping-active-dark;
      }
    }

    &.inactive {
      background-color: $color-grouping-inactive;

      @include hocus() {
        background-color: $color-grouping-inactive-dark;
      }
    }

    &.disabled {
      cursor: not-allowed;
      border: 1px solid $color-gray-light;
    }

    &.mixed {
      background: linear-gradient(135deg,
          $color-grouping-active,
          $color-grouping-active 50%,
          $color-grouping-inactive 50%,
          $color-grouping-inactive );

      @include hocus() {
        background: linear-gradient(135deg,
            $color-grouping-active-dark,
            $color-grouping-active-dark 50%,
            $color-grouping-inactive-dark 50%,
            $color-grouping-inactive-dark );
      }
    }
  }
}

.table-grouping-wrapper {
  height: 100%;
  width: 100%;
  overflow: auto;
  @include custom-scrollbar();
}
</style>
